// @flow strict
import type { Node as ReactNode } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useSiteMetadata } from '../../hooks';
import styles from './Layout.module.scss';
import Header from '../Header';
import Footer from '../Footer';

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  socialImage?: string,
};

const Layout = ({ children, title, description, socialImage = '' }: Props) => {
  const { author, copyright, url } = useSiteMetadata();
  const metaImage = socialImage || author.photo;
  const metaImageUrl = url + metaImage;
  return (
    <div>
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:site_name' content={title} />
        <meta property='og:image' content={metaImageUrl} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content={metaImageUrl} />
      </Helmet>
      <ParallaxProvider>
        <Header />
        <div className={styles.layout}>{children}</div>
        <Footer author={author} copyright={copyright} />
      </ParallaxProvider>
    </div>
  );
};

export default Layout;

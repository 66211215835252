// @flow strict
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import styles from './Header.module.scss';
import { useSiteMetadata } from '../../hooks';
import Hamburger from '../Hamburger/Hamburger';
import AsCircleLogo from '../../assets/svg/as-circle-logo.svg';

const Header = () => {
  const [isShowSideBar, setIsShowSideBar] = useState(false);
  const isOverMdSize = useMediaQuery({ query: '(min-width: 960px)' });
  const siteMetadata = useSiteMetadata();
  const pageRef = useRef();
  useEffect(() => {
    if (isOverMdSize) setIsShowSideBar(false);
  }, [isOverMdSize]);
  useEffect(() => {
    pageRef.current.scrollIntoView();
  });
  const { menu, title } = siteMetadata;
  const toggleSidebar = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowSideBar((prev) => !prev);
  });

  const MenuTabs = () =>
    menu.map((item) => (
      <li className={styles['header__menu-item']} key={item.path}>
        <Link
          to={item.path}
          className={styles['header__menu-item-link']}
          activeClassName={styles['header__menu-item-link--active']}
        >
          {item.label}
        </Link>
      </li>
    ));

  const SidebarTabs = () =>
    menu.map((item) => (
      <li className={styles['header__sidebar-item']} key={item.path}>
        <Link
          to={item.path}
          className={styles['header__sidebar-item-link']}
          activeClassName={styles['header__sidebar-item-link--active']}
        >
          {item.label}
        </Link>
      </li>
    ));

  const sidebarCss =
    styles[`header__sidebar--${isShowSideBar ? 'opened' : 'closed'}`];
  return (
    <header className={styles['header']} ref={pageRef}>
      <div className={styles['header__logo']}>
        <Link to='/'>
          <AsCircleLogo />
          <h2>{title}</h2>
        </Link>
      </div>
      <ul className={`${styles['header__menu']}`}>
        <MenuTabs />
      </ul>
      <ul className={`${styles['header__sidebar']} ${sidebarCss}`}>
        <SidebarTabs />
      </ul>
      <Hamburger isToggle={isShowSideBar} onClick={toggleSidebar} />
      <div
        className={
          styles[
            `header__sidebar-screen-cover--${isShowSideBar ? 'show' : 'hide'}`
          ]
        }
        onClick={toggleSidebar}
      />
    </header>
  );
};

export default Header;

import React from 'react';
import styles from './Hamburger.module.scss';

interface Props {
  isToggle: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const Hamburger = ({ isToggle = false, onClick = null }: Props) => {
  const className = `${styles['hamburger']} ${
    isToggle ? styles['hamburger--open'] : ''
  }`;
  return (
    <div className={styles['hamburger__container']} onClick={onClick}>
      <div className={className}>
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};

export default Hamburger;

// @flow strict
import React from 'react';
import { getContactHref, getIcon } from '../../../utils';
import Icon from '../../Icon';
import styles from './Contacts.module.scss';

type Props = {
  contacts: {
    [string]: string,
  },
  isFullWidth?: boolean,
};

const Contacts = ({ contacts, isFullWidth = false }: Props) => {
  const contactStyle = `${styles['contacts__list']} ${
    isFullWidth ? styles['contacts__list--full-width'] : ''
  }`;
  const liStyle = `${styles['contacts__list-item']} ${
    isFullWidth ? styles['contacts__list-item--full-width'] : ''
  }`;
  return (
    <div className={styles['contacts']}>
      <ul className={contactStyle}>
        {Object.keys(contacts).map((name) =>
          !contacts[name] ? null : (
            <li className={liStyle} key={name}>
              <a
                className={styles['contacts__list-item-link']}
                href={getContactHref(name, contacts[name])}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Icon name={name} icon={getIcon(name)} />
              </a>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default Contacts;

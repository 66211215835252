// @flow strict
import React from 'react';
import styles from './Footer.module.scss';
import Contacts from '../Sidebar/Contacts';
import Copyright from '../Sidebar/Copyright';

type Props = {
  author: {
    contacts: {
      email: string,
      linkedin: string,
      github: string,
      twitter: string,
    },
  },
  copyright: string,
};
const Footer = (props: Props) => {
  const author = props?.author ?? {};
  const copyright = props?.copyright ?? '';
  const isContactsDataExist = 'contacts' in author;
  if (!isContactsDataExist) return null;
  return (
    <footer className={styles['footer']}>
      <div className={styles['footer__body']}>
        <Contacts contacts={author.contacts} isFullWidth={true} />
        <Copyright copyright={copyright} />
      </div>
    </footer>
  );
};

export default Footer;
